<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>
            <b-breadcrumb-item active>組織資料中心</b-breadcrumb-item>
            <b-breadcrumb-item active>組織管理</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>

    <b-card
      v-if="showOrgCard"
      :title="`【${organization.name}】組織管理`"
      class="grid-margin"
    >
      <div class="row">
        <div class="col-9 col-xl-6">
          <b-form-group label-cols-lg="2" label="組織名稱">
            <b-form-input
              type="text"
              :value="organization.name"
              :disabled="true"
            ></b-form-input>
          </b-form-group>
          <b-form-group label-cols-lg="2" label="組織代碼">
            <b-form-input
              type="text"
              :value="organization.code"
              :disabled="true"
            ></b-form-input>
          </b-form-group>
          <b-form-group label-cols-lg="2" label="組織編號">
            <b-form-input
              type="text"
              :value="organization.id"
              :disabled="true"
            ></b-form-input>
          </b-form-group>
        </div>
      </div>
    </b-card>

    <b-card class="grid-margin" v-if="hasMerchantPermission">
      <div class="row d-flex mb-4 mb-xl-2 justify-content-between">
        <h4 class="mb-2 mb-xl-0 font-weight-bold">通路清單</h4>
        <b-button
          v-if="checkPermission([consts.MERCHANT_MODIFY])"
          class="flex-shrink-0 mb-2 mb-xl-0 mr-2"
          variant="primary"
          :to="{ name: 'MerchantCreate' }"
        >
          <i class="fa fa-plus"></i>新增通路
        </b-button>
      </div>
      <b-table striped hover responsive :items="merchants" :fields="fields">
        <template #cell(actions)="data">
          <img
            v-if="data.item.type === 'LINE'"
            class="menu-icons mr-1"
            :style="lineIconStyle"
          />

          <b-button-group>
            <b-button
              v-if="checkPermission([consts.ORGANIZATION_MERCHANT_VARIABLE])"
              variant="inverse-info"
              class="icon-btn"
              :to="{
                name: 'OrganizationVariableListByMerchant',
                params: { merchant_id: data.item.id },
              }"
            >
              <span class="mdi mdi-settings"></span> 設定檔
            </b-button>
            <b-button
              v-if="checkPermission([consts.ORGANIZATION_MERCHANT_TEMPLATE])"
              variant="inverse-light"
              class="icon-btn"
              :to="{
                name: 'TemplateList',
                params: { merchant_id: data.item.id },
              }"
            >
              <span class="mdi mdi-comment-text"></span> 通知樣板
            </b-button>
            <b-button variant="inverse-light" class="icon-btn">
              <span class="mdi mdi-cube-outline"></span> 模組
            </b-button>
            <b-button
              variant="inverse-primary"
              class="icon-btn"
              :to="{
                name: 'MerchantShow',
                params: { merchant_id: data.item.id },
              }"
            >
              <span class="mdi mdi-eye"></span> 查看
            </b-button>
            <b-button
              v-if="checkPermission([consts.MERCHANT_MODIFY])"
              variant="inverse-warning"
              class="icon-btn"
              :to="{
                name: 'MerchantEdit',
                params: { merchant_id: data.item.id },
              }"
            >
              <span class="mdi mdi-lead-pencil"></span> 編輯
            </b-button>
            <b-button
              v-if="checkPermission([consts.MERCHANT_DELETE])"
              variant="inverse-danger"
              class="icon-btn"
              @click="handleDelete(data.item)"
            >
              <span class="mdi mdi-delete"></span> 刪除
            </b-button>
            <b-tooltip target="tooltip-target-1" triggers="hover">
              設定檔管理
            </b-tooltip>
          </b-button-group>
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { format } from "date-fns";
import merchantApi from "../../../apis/merchant";
import PermissionChecker from "@/utils/PermissionChecker";
import * as consts from "@/consts";

export default {
  data() {
    const path = require("@/assets/images/sidebar/line-brands-solid.svg");

    return {
      fields: [
        {
          key: "name",
          label: "名稱",
        },
        {
          key: "id",
          label: "編號",
        },
        {
          key: "type",
          label: "類型",
        },
        {
          key: "is_enabled",
          label: "狀態",
          formatter: (value) => {
            if (parseInt(value, 10) === 0) {
              return "已停用";
            }
            if (parseInt(value, 10) === 1) {
              return "啟用";
            }

            return value;
          },
        },
        {
          key: "updated_at",
          label: "更新日",
          formatter: (value) => format(new Date(value), "yyyy-MM-dd HH:mm"),
        },
        {
          key: "actions",
          label: "管理",
        },
      ],
      merchants: [],
      consts: consts,
      lineIconStyle: {
        "mask-image": `url(${path})`,
        "-webkit-mask-image": `url(${path})`,
        "background-color": "green",
        "border-radius": "0",
      },
    };
  },
  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
    }),
    hasMerchantPermission() {
      return this.$permissions.has(this.$permissions.consts.MERCHANT_LIST_VIEW);
    },
    showOrgCard() {
      return (
        this.$permissions.has(this.$permissions.consts.ORGANIZATION_VARIABLE) ||
        this.$permissions.has(this.$permissions.consts.ROLE_LIST_VIEW) ||
        this.$permissions.has(this.$permissions.consts.MODULE_LIST_VIEW) ||
        this.$permissions.has(this.$permissions.consts.KEYWORD_LIST_VIEW) ||
        this.$permissions.has(this.$permissions.consts.SQL_RUNNER_VIEW)
      );
    },
  },
  mounted() {
    if (this.hasMerchantPermission) {
      this.fetchMerchants();
    }
  },
  methods: {
    ...mapActions("general", []),
    format,
    async fetchMerchants(page) {
      this.showLoading = true;

      const params = {
        page: page || this.currentPage,
      };
      if (this.keyword) params.keyword = this.keyword;

      try {
        const { data } = await merchantApi.list(this.organization.id);
        this.merchants = data.data;
        this.totalRows = data.meta.total;
        this.perPage = data.meta.per_page;
      } catch (error) {
        console.log(error);
        alert("取得 merchant 錯誤");
      } finally {
        this.showLoading = false;
      }
    }, // end fetchMerchants
    checkPermission(permissions) {
      const checker = new PermissionChecker();
      return checker.check(permissions);
    },
    async updateOaOnly(merchant_id) {
      this.showLoading = true;

      try {
        const { data } = await merchantApi.updateOaOnly(merchant_id);
        if (data.status === "success") {
          this.$swal.fire({
            title: "同步 Line OA 成功",
            type: "success",
          });
        } else {
          this.$swal.fire({
            title: "同步 Line OA 失敗",
            type: "error",
          });
        }
      } catch (error) {
        console.log(error);
        this.$swal.fire({
          title: "同步 Line OA 失敗",
          type: "error",
        });
      } finally {
        this.showLoading = false;
      }
    },
    async updateFriends(merchant_id) {
      this.showLoading = true;

      try {
        const { data } = await merchantApi.updateFriends(merchant_id);
        if (data.status === "success") {
          this.$swal.fire({
            title: "同步 Line 好友成功",
            type: "success",
          });
        } else {
          this.$swal.fire({
            title: "同步 Line 好友失敗",
            type: "error",
          });
        }
      } catch (error) {
        console.log(error);
        this.$swal.fire({
          title: "同步 Line 好友失敗",
          type: "error",
        });
      } finally {
        this.showLoading = false;
      }
    },
    handleCreate() {
      this.$router.push({
        name: "MerchantCreate",
      });
    },
    async handleDelete(merchant) {
      this.$swal
        .fire({
          type: "warning",
          title: "你確定要刪除嗎？",
          html: `
            <div class="d-block">
              <div class="my-3">
                <div>通路名稱：${merchant.name}</div>
                <div>通路類型：${merchant.type}</div>
              </div>
              <div>刪除後，相關資料將無法回復，<br/>請您確認！</div>
            </div>`,
          confirmButtonText: "確定刪除",
          cancelButtonText: "不刪除",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#3085d6",
          reverseButtons: true,
        })
        .then(async (result) => {
          if (result.value) {
            try {
              this.showLoading = true;
              await merchantApi.deleteMerchant(
                this.organization.id,
                merchant.id
              );
              await this.fetchMerchants();
            } catch (error) {
              console.log("");
            }
            this.showLoading = false;
          }
        });
    },
  }, // end methods
};
</script>

<style scoped lang="scss">
.tooltip {
  opacity: 1;
}

.grid-margin {
  .btn + .btn {
    margin-left: 0.5rem;
  }
}
</style>
