var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row page-title-header"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"page-header"},[_c('b-breadcrumb',{staticClass:"m-0"},[_c('b-breadcrumb-item',{attrs:{"to":{
              name: 'DashboardHome',
            }}},[_c('i',{staticClass:"fa fa-home"})]),_c('b-breadcrumb-item',{attrs:{"active":""}},[_vm._v("組織資料中心")]),_c('b-breadcrumb-item',{attrs:{"active":""}},[_vm._v("組織管理")])],1)],1)])]),(_vm.showOrgCard)?_c('b-card',{staticClass:"grid-margin",attrs:{"title":("【" + (_vm.organization.name) + "】組織管理")}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-9 col-xl-6"},[_c('b-form-group',{attrs:{"label-cols-lg":"2","label":"組織名稱"}},[_c('b-form-input',{attrs:{"type":"text","value":_vm.organization.name,"disabled":true}})],1),_c('b-form-group',{attrs:{"label-cols-lg":"2","label":"組織代碼"}},[_c('b-form-input',{attrs:{"type":"text","value":_vm.organization.code,"disabled":true}})],1),_c('b-form-group',{attrs:{"label-cols-lg":"2","label":"組織編號"}},[_c('b-form-input',{attrs:{"type":"text","value":_vm.organization.id,"disabled":true}})],1)],1)])]):_vm._e(),(_vm.hasMerchantPermission)?_c('b-card',{staticClass:"grid-margin"},[_c('div',{staticClass:"row d-flex mb-4 mb-xl-2 justify-content-between"},[_c('h4',{staticClass:"mb-2 mb-xl-0 font-weight-bold"},[_vm._v("通路清單")]),(_vm.checkPermission([_vm.consts.MERCHANT_MODIFY]))?_c('b-button',{staticClass:"flex-shrink-0 mb-2 mb-xl-0 mr-2",attrs:{"variant":"primary","to":{ name: 'MerchantCreate' }}},[_c('i',{staticClass:"fa fa-plus"}),_vm._v("新增通路 ")]):_vm._e()],1),_c('b-table',{attrs:{"striped":"","hover":"","responsive":"","items":_vm.merchants,"fields":_vm.fields},scopedSlots:_vm._u([{key:"cell(actions)",fn:function(data){return [(data.item.type === 'LINE')?_c('img',{staticClass:"menu-icons mr-1",style:(_vm.lineIconStyle)}):_vm._e(),_c('b-button-group',[(_vm.checkPermission([_vm.consts.ORGANIZATION_MERCHANT_VARIABLE]))?_c('b-button',{staticClass:"icon-btn",attrs:{"variant":"inverse-info","to":{
              name: 'OrganizationVariableListByMerchant',
              params: { merchant_id: data.item.id },
            }}},[_c('span',{staticClass:"mdi mdi-settings"}),_vm._v(" 設定檔 ")]):_vm._e(),(_vm.checkPermission([_vm.consts.ORGANIZATION_MERCHANT_TEMPLATE]))?_c('b-button',{staticClass:"icon-btn",attrs:{"variant":"inverse-light","to":{
              name: 'TemplateList',
              params: { merchant_id: data.item.id },
            }}},[_c('span',{staticClass:"mdi mdi-comment-text"}),_vm._v(" 通知樣板 ")]):_vm._e(),_c('b-button',{staticClass:"icon-btn",attrs:{"variant":"inverse-light"}},[_c('span',{staticClass:"mdi mdi-cube-outline"}),_vm._v(" 模組 ")]),_c('b-button',{staticClass:"icon-btn",attrs:{"variant":"inverse-primary","to":{
              name: 'MerchantShow',
              params: { merchant_id: data.item.id },
            }}},[_c('span',{staticClass:"mdi mdi-eye"}),_vm._v(" 查看 ")]),(_vm.checkPermission([_vm.consts.MERCHANT_MODIFY]))?_c('b-button',{staticClass:"icon-btn",attrs:{"variant":"inverse-warning","to":{
              name: 'MerchantEdit',
              params: { merchant_id: data.item.id },
            }}},[_c('span',{staticClass:"mdi mdi-lead-pencil"}),_vm._v(" 編輯 ")]):_vm._e(),(_vm.checkPermission([_vm.consts.MERCHANT_DELETE]))?_c('b-button',{staticClass:"icon-btn",attrs:{"variant":"inverse-danger"},on:{"click":function($event){return _vm.handleDelete(data.item)}}},[_c('span',{staticClass:"mdi mdi-delete"}),_vm._v(" 刪除 ")]):_vm._e(),_c('b-tooltip',{attrs:{"target":"tooltip-target-1","triggers":"hover"}},[_vm._v(" 設定檔管理 ")])],1)]}}],null,false,2424148978)})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }